// Variables
$colors: ( 'primary': #005ea2, 'primary-outline': #0076d6, 'secondary': #e1f3f8, 'white': #ffffff, 'black': #000000 );

// Mixins
@mixin hover-darken($color, $percentage: 10%) {
    &:hover {
        background-color: darken($color, $percentage) !important;
    }
}

// Buttons
.btn {
    &.btn-primary {
        background-color: map-get($colors, 'primary') !important;
        color: map-get($colors, 'white') !important;
        @include hover-darken(map-get($colors, 'primary'));
    }

    &.btn-secondary {
        background-color: map-get($colors, 'secondary') !important;
        color: map-get($colors, 'black');
        @include hover-darken(map-get($colors, 'secondary'));
    }

    &.b-outline-primary {
        background-color: map-get($colors, 'white') !important;
        color: map-get($colors, 'primary-outline');
        border: 1px solid map-get($colors, 'primary-outline');

        &:hover {
            background-color: map-get($colors, 'primary-outline') !important;
            color: map-get($colors, 'white');
        }
    }
}

.btn-primary {
    background-color: map-get($colors, 'primary') !important;
    color: map-get($colors, 'white');
    @include hover-darken(map-get($colors, 'primary'));
}


// Colors and Backgrounds
.fill {
    &-primary {
        color: map-get($colors, 'primary');
    }

    &-secondary {
        color: map-get($colors, 'secondary');
    }
}

.bg-primary {
    background-color: map-get($colors, 'primary') !important;
    color: map-get($colors, 'white');
}

.text-primary {
    color: map-get($colors, 'primary') !important;
}

// Links and Navigation
a {
    color: map-get($colors, 'primary');

    &:hover {
        color: darken(map-get($colors, 'primary'), 15%);
    }
}

.nav-tabs .nav-link {
    &.active,
    &:hover {
        border-top: 3px solid map-get($colors, 'primary');
        font-weight: 700;
    }
}
