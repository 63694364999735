.tp-search {
    display: none;
}

.search-container {
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
}


.search-controls {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    flex-wrap: wrap;
}

.control-group {
    flex: 1;
    min-width: 200px;
}

.control-label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.control-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
}

.or-divider {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #666;
    padding: 0 10px;
    align-self: flex-end;
    margin-bottom: 8px;
}

.button-group {
    display: flex;
    gap: 10px;
    align-self: flex-end;
}

