/*.small-font {
    font-size: .9rem;
}*/

h1 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 8px;
}

.button {
    outline: none !important;
    box-shadow: none !important;
}

.question_btn {
    padding: 0;
    margin: 0 10px;
    display: flex;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff !important;
    color: #3d316a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    border: none;
}

.is-nothing {
    padding: 0;
    border: none;
    height: auto;
}

.is-selfvcentered {
    display: flex;
    align-items: center;
}

.is-end {
    justify-content: flex-end;
}

.b_blue {
    background-color: #3d316a;
    color: #fff;
}

    .b_blue a {
        color: #fff;
    }

select {
    max-width: 100%;
    width: 100%;
}

.two_btn {
    max-width: 153px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.breadcrumb li + li::before {
    line-height: 1;
}

.metaDataTableModal .modal-content {
    width: 700px;
}

.metaDataTableModal tbody tr td:first-child {
    width: 30%;
}

.modal-card-title {
    flex-shrink: initial;
}

img.digg {
    background: #14589e;
}

img.email {
    background: #dd4b39;
}

img.linkedin {
    background: #007fb1;
}

img.facebook {
    background: #3b5998;
}

img.twitter {
    background: #00aced;
}

.smal_line {
    font-size: 0;
    line-height: 6px;
    margin-bottom: 7px;
}

.subnav {
    padding: 10px 0;
}

    .subnav a {
        margin: 0 12px;
        font-size: 14px;
    }

        .subnav a:hover {
            text-shadow: 0 0 1px #fff;
        }

.big_nav {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 3px;
}

    .big_nav a {
        font-size: 24px;
    }

.section_bred {
    margin: 12px 0;
}

    .section_bred a {
        font-size: 14px;
    }

.social_share .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 14px;
}

    .social_share .dropdown-item img {
        margin-right: 5px;
    }

.linl_block_wrap {
    display: flex;
    justify-content: flex-end;
}

    .linl_block_wrap .links_block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 200;
        border: 1px solid #e5e5e5;
        padding: 10px;
    }

        .linl_block_wrap .links_block a {
            width: 190px;
        }

            .linl_block_wrap .links_block a img {
                width: 25px;
                height: auto;
                margin-right: 5px;
            }

                .linl_block_wrap .links_block a img.ico_pdf {
                    width: 16px;
                }

.info_row .container {
    border: 1px solid #e5e5e5;
    margin-bottom: 4rem;
}

.info_row .row_title {
    font-size: 16px;
    font-weight: 700;
    background-color: #e5e5e5;
    color: #000;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 30px;
}

.info_row .row_body {
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
}

    .info_row .row_body a {
        padding-right: 15px;
        background: url(../../public/images/icon_out.png) 100% 50% no-repeat;
    }

.footer {
    margin-top: auto;
    padding: 0;
}

.footer_top {
    background: #c0c0c0;
    padding: 20px 5px;
}

    .footer_top b {
        margin-right: 10px;
    }

    .footer_top a {
        margin: 0 5px;
    }

    .footer_top .column {
        align-items: center;
    }

.footer_bot {
    padding: 20px 15px;
}

span[class*="sprite-24"] {
    width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    min-width: 25px;
    max-width: 25px;
    display: block;
}

.sprite-24-govd {
    background: url(../../public/images/ss.png) no-repeat 0px -85px;
}

.sprite-24-facebook {
    background: url(../../public/images/ss.png) no-repeat -52px -85px;
}

.sprite-24-twitter {
    background: url(../../public/images/ss.png) no-repeat -78px -85px;
}

.sprite-24-linkedin {
    background: url(../../public/images/ss.png) no-repeat -104px -85px;
}

.sprite-24-rss {
    background: url(../../public/images/ss.png) no-repeat -155px -85px;
}

.sprite-24-listen {
    background: url(../../public/images/sm.png) no-repeat -155px -85px;
}

.sprite-24-watch {
    background: url(../../public/images/sm.png) no-repeat -129px -85px;
}

.sprite-24-ecards {
    background: url(../../public/images/sm.png) no-repeat -103px -85px;
}

.sprite-24-youtube {
    background: url(../../public/images/ss.png) no-repeat -26px -85px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

:root {
    --lh: 1.4rem;
}

.truncate-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.nav-tabs .nav-link.active {
    border-top-width: 4px !important;
}

.top_wrapper {
    max-width: 100vw;
    overflow-x: hidden;
}

.tabs {
    display: flex;
    flex-direction: column;
    white-space: inherit;
}

    .tabs ul {
        align-items: inherit;
        border-bottom: none;
        margin-bottom: -1px;
        flex-wrap: wrap;
    }

    .tabs li {
        height: 60px;
    }

        .tabs li:not(.active) {
            border: 1px solid #e8e8e8;
        }

        .tabs li.is-active a {
            font-weight: 700;
            color: #000;
            background: #fafafa !important;
        }

    .tabs a, .tabs .tabBtn {
        width: 230px;
        padding: 3px;
        font-size: 18px;
        height: 100%;
        text-align: center;
        position: relative;
        border-radius: 5px 5px 0 0;
    }

.tab-content .tab_in {
    display: none;
}

    .tab-content .tab_in:first-child {
        display: block;
    }

.tabs_block .tab-content {
    border-radius: 0 0 5px 5px;
    border: 1px solid #dbdbdb;
    padding: 1em;
}

.nav-link.active {
    background-color: #fff !important;
}

.table th.th-tertiary, .table td.th-tertiary {
    background-color: #e1f3f8 !important;
}

.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd),
.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd) th:not(.bg-primary):not(.bg-secondary-important th),
.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd) td:not(.bg-secondary-important td) {
    background-color: #f5f6f7 !important;
}

    .theme-purple .table.table-striped-light tbody tr:nth-of-type(odd).bg-secondary-important, .table th.bg-secondary-important,
    .bg-secondary-important, .table .bg-secondary-important th, .table .bg-secondary-important td {
        background-color: #e1f3f8 !important;
    }

tbody tr td:first-of-type {
    text-align: left;
}

.bordered_grey_bot {
    border: 1px solid #dbdbdb;
    margin-top: 20px;
}

    .bordered_grey_bot .top {
        padding: 12px 20px;
    }

    .bordered_grey_bot .bot {
        background-color: #f0f0f0;
        padding: 20px;
    }

    .bordered_grey_bot .filter {
        background-color: #fff;
        border: 1px solid #dbdbdb;
    }

        .bordered_grey_bot .filter .title {
            font-size: 16px;
            background-color: #005ea2;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            margin-bottom: 0;
        }

        .bordered_grey_bot .filter .item {
            padding: 5px 15px 5px 25px;
            font-size: 14px;
            border-bottom: 1px solid #dbdbdb;
            cursor: pointer;
        }

            .bordered_grey_bot .filter .item.active {
                background: url(../../public/images/check.png) 5px 50% no-repeat;
            }

            .bordered_grey_bot .filter .item:hover {
                background-color: #dbdbdb56;
            }

    .bordered_grey_bot .filter_result {
        background-color: #fff;
        border: 1px solid #dbdbdb;
    }

        .bordered_grey_bot .filter_result .title {
            font-size: 16px;
            background-color: #005ea2;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            margin-bottom: 0;
        }

        .bordered_grey_bot .filter_result .filter_result_bot {
            padding: 20px;
        }

        .bordered_grey_bot .filter_result .result_table {
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }

            .bordered_grey_bot .filter_result .result_table th {
                background-color: #c5bee1;
            }

.bordered_grey {
    border: 1px solid #dbdbdb;
    padding: 15px 10px;
}

.th_blue thead th {
    background-color: #c5bee1;
}

.blue_title th {
    color: #fff !important;
    background-color: #3d316a;
}

.blue_title .table_title {
    color: #fff;
    display: flex;
    /*justify-content: space-between;*/
}

.select_blue {
    background-color: #dbeaec;
}

.gray_background {
    background-color: #d4d4d4;
}

.white_background {
    background-color: #ffffff;
}

.purple_background {
    background-color: #f2c4fc;
}

@media (max-width: 968.82px) {
    .tabs_block ul {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
    }

        .tabs_block ul a {
            width: auto;
            border: none;
            border-bottom: 1px solid #dbdbdb;
            font-size: 14px;
        }

        .tabs_block ul li.is-active a {
            border: none;
            border-bottom: 3px solid #dbdbdb !important;
            border-bottom-color: #dbdbdb !important;
        }

    .section_bred,
    .social_share,
    h1 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .table-container table {
        font-size: 14px;
    }

    .modal-card-title {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .table-container table {
        font-size: 12px;
    }
}

.btn-secondary:focus {
    border: none !important;
    box-shadow: none !important;
}

*:focus, .btn-secondary:focus {
    outline: 3px auto rgba(255,102,1,.9) !important;
}

/*styling form exporting to pdf*/
@media print {
    body, html {
        background-color: #ffffff !important;
        -webkit-print-color-adjust: exact;
    }

    #pdfDiv {
        padding-left: 0px;
    }

    .tab1PdfDiv.Q002 {
        height: 865px !important;
    }

        .tab1PdfDiv.Q002 .filter_result_bot {
            height: 860px;
        }

        .tab1PdfDiv.Q002 svg {
            position: relative;
            top: -17px;
        }

    .Q002 .tab1FooterCycleCount {
        position: relative;
        bottom: 90px;
    }

        .Q002 .tab1FooterCycleCount.true {
            margin-top: 40px;
        }

    #tab2Title2 {
        margin-top: -5px !important;
    }

    .bordered_grey_bot {
        margin-top: 0px;
    }

    .tabContent1 {
        padding-top: 0px !important;
    }

    .tab1_result_table {
        top: -50px;
    }

    th.pr-2-modify-dot6rem {
        padding-right: 1rem !important;
    }

    .header-wrapper, .site-title, .filter {
        display: none !important;
    }

    body, #tabContent, div.tabs_block, div.bordered_grey_bot,
    div.bordered_grey, title {
        border: none !important;
    }

    .is-selfvcentered {
        display: block !important;
    }

    .tabOne_ClinicInfo {
        padding-right: 0px !important;
        width: 270px !important;
        flex: none !important;
        margin-left: -20px !important;
    }

    .tableOne_ServiceProfile_table table {
        width: 405px !important;
        margin-left: -5px;
    }

    .tabOne_tableSummary {
        width: 365px !important;
        margin-left: 75px !important;
    }

    .tabOne_tableProfile {
        width: 405px !important;
        margin-left: -5px;
    }

        .tabOne_tableProfile table thead th:first-child {
            width: 302px !important;
        }

    .tabOneNat_ClinicInfo {
        width: 100% !important;
        margin-left: -20px;
    }

    .tableOneNat_ServiceProfile_table table {
        width: 340px !important;
        margin-left: -20px;
    }

        .tableOneNat_ServiceProfile_table table thead tr th:last-child {
            padding-left: 5px;
            padding-right: 5px;
        }

    .tabOneNat_tableSummary {
        width: 340px !important;
        margin-left: 30px;
    }

    .tabOneNat_tableProfile table {
        width: 340px !important;
        margin-left: 5px;
    }

    .tab_in.px-5 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    h1.tabName, div.printFooter, div#tab2Title1,
    #tab2Title2 {
        display: block !important;
    }

    select#tab2Question {
        font-weight: bold;
        color: #000;
        border: none; /* Remove all borders */
        outline: none; /* Remove outline (if any) */
        -webkit-appearance: none; /* Remove default styling on Safari */
        -moz-appearance: none; /* Remove default styling on Firefox */
        appearance: none; /* Remove default styling on other browsers */
        box-shadow: none; /* Remove any box shadow */
        background: transparent; /* Remove background color */
        width: 100% !important;
    }

    .bordered_grey_bot .top, #tab2Question, div.lblTab3QuestionWrap {
        padding-left: 0px !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    .table th.bg-primary {
        background: #005ea2 !important;
    }

    .filter_result_wrap {
        flex: unset !important;
        max-width: 100% !important;
    }

    #pdfDivTbl2, #pdfDivTbl3 {
        page-break-after: always;
    }

        #pdfDivTbl2 .table-bordered th.border-0,
        #pdfDivTbl3 .table-bordered th.border-0 {
            border: none !important;
        }

    #legendTextWrapper {
        font-size: 15px;
        width: 1000px;
    }

    .container.body-wrapper {
        min-width: 100% !important;
    }
}

@media print {
    /* Remove forced page breaks */
    .chart-container {
        break-inside: avoid;
        margin: 0;
        padding: 0;
    }

        .chart-container svg {
            height: 800px;
            width: 755px;
            display: block;
            margin: 0 !important;
            padding: 0 !important;
        }

    #pdfDiv {
        margin: 0 !important;
        padding: 0 !important;
    }

    /* Remove forced page break on footer */
    .printFooter {
        margin-top: 20px;
        break-inside: avoid;
    }

    /* Keep facility name with content */
    #pdfDivFacilityName {
        break-after: avoid;
        margin-bottom: 10px !important;
    }

    /* Keep description text with content */
    #tab2Title1, #tab2Title2 {
        margin-bottom: 10px !important;
        break-after: avoid;
    }

    .tab-header {
        break-after: avoid;
    }
}

.control-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
}

