
.nav-tabs .nav-link.active {
    border-top-width: 4px !important;
}

.top_wrapper {
    max-width: 100vw;
    overflow-x: hidden;
}

.tabs {
    display: flex;
    flex-direction: column;
    white-space: inherit;

    ul {
        align-items: inherit;
        border-bottom: none;
        margin-bottom: -1px;
        flex-wrap: wrap;
    }

    li {
        height: auto;

        &.is-active {
            a {
                font-weight: 700;
                color: #000;
            }
        }

        &:not(.active) {
            border: 1px solid #e8e8e8;
        }
    }

    a {
        width: 172px;
        padding: 7px;
        font-size: 14px;
        height: 100%;
        text-align: center;
        position: relative;
        border-radius: 5px 5px 0 0;
    }

    p {
        font-size: 14px;
    }
}

.tab-content .tab_in {
    display: none;

    &:first-child {
        display: block;
    }
}

.tabs_block {
    .tab-content {
        border-radius: 0 0 5px 5px;
        border: 1px solid #dbdbdb;
        padding: 1em;
    }
}

.nav-link.active {
    background-color: #fff !important;
}

.table th.th-tertiary, .table td.th-tertiary {
    background-color: #e1f3f8 !important;
}

.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd),
.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd) th:not(.bg-primary):not(.bg-secondary-important th),
.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd) td:not(.bg-secondary-important td) {
    background-color: #f5f6f7 !important;
}

.theme-purple .table.table-striped-light tbody tr:nth-of-type(odd).bg-secondary-important,
.bg-secondary-important, .table .bg-secondary-important th, .table .bg-secondary-important td {
    background-color: #e1f3f8 !important;
}

tbody tr td:first-of-type {
    text-align: left;
}

.bordered_grey_bot {
    border: 1px solid #dbdbdb;
    margin-top: 20px;

    .top {
        padding: 12px 20px;
    }

    .bot {
        background-color: #f0f0f0;
        padding: 20px;
    }

    .filter {
        background-color: #fff;
        border: 1px solid #dbdbdb;

        .title {
            font-size: 16px;
            background-color: #005ea2;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            margin-bottom: 0;
        }

        .item {
            padding: 5px 15px 5px 25px;
            font-size: 14px;
            border-bottom: 1px solid #dbdbdb;
            cursor: pointer;

            &.active {
                background: url(../../public/images/check.png) 5px 50% no-repeat;
            }

            &:hover {
                background-color: #dbdbdb56;
            }
        }
    }

    .filter_result {
        background-color: #fff;
        border: 1px solid #dbdbdb;

        .title {
            font-size: 16px;
            background-color: #005ea2;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            margin-bottom: 0;
        }

        .filter_result_bot {
            padding: 20px;
        }

        .result_table {
            display: flex;
            justify-content: center;
            padding: 15px 0;

            th {
                background-color: #c5bee1;
            }
        }
    }
}

.bordered_grey {
    border: 1px solid #dbdbdb;
    padding: 15px 10px;
}
// tables
.th_blue {
    thead th {
        background-color: #c5bee1;
    }
}

.blue_title {
    th {
        color: #fff !important;
        background-color: #3d316a;
    }

    .table_title {
        color: #fff;
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 968.82px) {
    .tabs_block {
        ul {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;

            a {
                width: auto;
                border: none;
                border-bottom: 1px solid #dbdbdb;
                font-size: 14px;
            }

            li.is-active a {
                border: none;
                border-bottom: 3px solid #dbdbdb !important;
                border-bottom-color: #dbdbdb !important;
            }
        }
    }

    .section_bred,
    .social_share,
    h1 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .table-container table {
        font-size: 14px;
    }

    .modal-card-title {
        font-size: 18px;
    }
}

.metaDataTableModal .modal-content {
    width: 700px;
}


.metaDataTableModal {

    .modal-content {
        width: 700px;
    }

    tbody tr td:first-child {
        width: 30%;
    }
}

.metaDataTableModal

@media (max-width: 500px) {
    .table-container table {
        font-size: 12px;
    }
}

.btn-secondary:focus {
    border: none !important;
    box-shadow: none !important;
}

:focus, .btn-secondary:focus {
    outline: 3px auto rgba(255,102,1,.9) !important;
}

/*styling form exporting to pdf*/
@media print {
    body, html {
        background-color: #ffffff !important;
    }

    .header-wrapper, .site-title, .filter {
        display: none !important;
    }

    body, #tabContent, div.tabs_block, div.bordered_grey_bot,
    div.bordered_grey {
        border: none !important;
    }

    .tab_in.px-5 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    div.tabName, div.printFooter, div#tab2Title1,
    #tab2Title2 {
        display: block !important;
    }

    select#tab2Question {
        font-weight: bold;
        color: #000;
        border: none; /* Remove all borders */
        outline: none; /* Remove outline (if any) */
        -webkit-appearance: none; /* Remove default styling on Safari */
        -moz-appearance: none; /* Remove default styling on Firefox */
        appearance: none; /* Remove default styling on other browsers */
        box-shadow: none; /* Remove any box shadow */
        background: transparent; /* Remove background color */
        width: 100% !important;
    }

    .bordered_grey_bot .top, #tab2Question, div.lblTab3QuestionWrap {
        padding-left: 0px !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    .filter_result_wrap {
        flex: unset !important;
        max-width: 100% !important;
    }

    #pdfDivTbl2, #pdfDivTbl3 {
        page-break-after: always;
    }

    #pdfDivTbl2 .table-bordered th.border-0,
    #pdfDivTbl3 .table-bordered th.border-0 {
        border: none !important;
    }

    #legendTextWrapper {
        font-size: 15px;
        width: 890px;
    }
    .container.body-wrapper {
        min-width: 100% !important;
    }
}
