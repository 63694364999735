// Variables
$primary-blue: #005ea2;
$primary-blue-light: #0076d6;
$white: #ffffff;
$text-dark: #1c1d1f;

// Mixins
@mixin superscript-reg($font-size: 24px, $top: 0) {
    position: relative;
    font-size: $font-size;
    color: $primary-blue;

    @if $top != 0 {
        top: $top;
    }
}

// Pagination styles
.pagination {
    li {
        margin-left: 15px;
    }
}

.page-link {
    color: $text-dark !important;
    border: none;

    &:hover {
        border-radius: 5px;
        background: $primary-blue-light;
        color: $white !important;
    }
}

.page-item.active .page-link {
    border-radius: 5px;
    background-color: $primary-blue !important;
    color: $white !important;
}

// Results section
.results-section {
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;
}

// Clinic status styles
.clinicStatusNone .superscript-reg {
    @include superscript-reg();
}

.clinicStatusReorganized .superscript-reg {
    @include superscript-reg(17px, -5px);
}

.clinicStatusClosed .superscript-reg {
    @include superscript-reg(16px, -5px);
}
