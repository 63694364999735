body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 8px;
}

.button {
    outline: none !important;
    box-shadow: none !important;
}

/*.small-font {
    font-size: .9rem;
}*/

.question_btn {
    padding: 0;
    margin: 0 10px;
    display: flex;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff !important;
    color: #3d316a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    border: none;
}

.is-nothing {
    padding: 0;
    border: none;
    height: auto;
}

.is-selfvcentered {
    display: flex;
    align-items: center;
}

.is-end {
    justify-content: flex-end;
}

.b_blue {
    background-color: #3d316a;
    color: #fff;

    a {
        color: #fff;
    }
}

select {
    max-width: 100%;
    width: 100%;
}

.two_btn {
    max-width: 153px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.breadcrumb li + li::before {
    line-height: 1;
}

.modal-card-title {
    flex-shrink: initial;
}

img.digg {
    background: #14589e;
}

img.email {
    background: #dd4b39;
}

img.linkedin {
    background: #007fb1;
}

img.facebook {
    background: #3b5998;
}

img.twitter {
    background: #00aced;
}

.smal_line {
    font-size: 0;
    line-height: 6px;
    margin-bottom: 7px;
}

.subnav {
    padding: 10px 0;

    a {
        margin: 0 12px;
        font-size: 14px;

        &:hover {
            text-shadow: 0 0 1px #fff;
        }
    }
}

.big_nav {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 3px;

    a {
        font-size: 24px;
    }
}

.section_bred {
    margin: 12px 0;

    a {
        font-size: 14px;
    }
}

.social_share {
    .dropdown-item {
        display: flex;
        align-items: center;
        font-size: 14px;

        img {
            margin-right: 5px;
        }
    }
}

.linl_block_wrap {
    display: flex;
    justify-content: flex-end;

    .links_block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 420px;
        border: 1px solid #e5e5e5;
        padding: 10px;

        a {
            width: 190px;

            img {
                width: 25px;
                height: auto;
                margin-right: 5px;

                &.ico_pdf {
                    width: 16px;
                }
            }
        }
    }
}

.info_row {
    .container {
        border: 1px solid #e5e5e5;
        margin-bottom: 4rem;
    }

    .row_title {
        font-size: 16px;
        font-weight: 700;
        background-color: #e5e5e5;
        color: #000;
        padding: 5px 10px;
        font-size: 14px;
        line-height: 30px;
    }

    .row_body {
        padding: 15px 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px 40px;

        a {
            padding-right: 15px;
            background: url(../../public/images/icon_out.png) 100% 50% no-repeat;
        }
    }
}

.footer {
    margin-top: auto;
    padding: 0;
}

.footer_top {
    background: #c0c0c0;
    padding: 20px 5px;

    b {
        margin-right: 10px;
    }

    a {
        margin: 0 5px;
    }

    .column {
        align-items: center;
    }
}

.footer_bot {
    padding: 20px 15px;
}

span[class*="sprite-24"] {
    width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    min-width: 25px;
    max-width: 25px;
    display: block;
}

.sprite-24-govd {
    background: url(../../public/images/ss.png) no-repeat 0px -85px;
}

.sprite-24-facebook {
    background: url(../../public/images/ss.png) no-repeat -52px -85px;
}

.sprite-24-twitter {
    background: url(../../public/images/ss.png) no-repeat -78px -85px;
}

.sprite-24-linkedin {
    background: url(../../public/images/ss.png) no-repeat -104px -85px;
}

.sprite-24-rss {
    background: url(../../public/images/ss.png) no-repeat -155px -85px;
}

.sprite-24-listen {
    background: url(../../public/images/sm.png) no-repeat -155px -85px;
}

.sprite-24-watch {
    background: url(../../public/images/sm.png) no-repeat -129px -85px;
}

.sprite-24-ecards {
    background: url(../../public/images/sm.png) no-repeat -103px -85px;
}

.sprite-24-youtube {
    background: url(../../public/images/ss.png) no-repeat -26px -85px;
}

//fix tp4
.sr-only-focusable:active, .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*this is for the description ellipsis text on tab 2,3,4,5*/
:root {
    --lh: 1.4rem;
}

.truncate-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
