%clinic-status-base {
    position: relative;
}

.clinicStatusBottom {
    &Closed {
        @extend %clinic-status-base;
        top: -10px;
        font-size: 16px;
    }

    &None {
        @extend %clinic-status-base;
        top: -4px;
        font-size: 24px;
    }

    &Reorganized {
        @extend %clinic-status-base;
        top: -8px;
        font-size: 17px;
    }
}
