.custom-navbar {
    background: linear-gradient(90deg,#0057b7 0,#0081a1 100%);
    padding: 0.5rem 1rem;
}

    .custom-navbar .nav-link {
        padding: 0.5rem 0;
    }

        .custom-navbar .nav-link:hover {
            text-decoration: underline;
        }
